<template>
  <el-card>
    <div class="form-header">
      <el-form inline :model="formData">
        <el-form-item prop="">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dataChange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadData">查询</el-button>
        </el-form-item>
      </el-form>
      <div>
        <custom-export v-show="$ButtonShow('export1')" type="6" :params="formData">导出调查汇总分析表</custom-export>
        <custom-export v-show="$ButtonShow('export2')" type="7" :params="formData">导出满意率调查问卷表({{detail.dcxx[5]}}份)</custom-export>
      </div>
    </div>

    <div class="bg">
      <div class="content">
        <span class="park">{{ parkName }}</span>
        <span class="title">游客满意率调查汇总分析表</span>
        <div class="tips">&emsp;本次汇总的调查表调查起止日期：{{ detail.dcxx[0] }}年{{ detail.dcxx[1] }}月{{ detail.dcxx[2] }}日 ~ {{ detail.dcxx[3] }}月{{ detail.dcxx[4] }}日，涉及调查表共{{ detail.dcxx[5] }}份</div>
        <table v-if="detail.dcxx[5] > 0">
          <tr>
            <td colspan="8">
              <p>一、游客基本情况</p>
              <p>
                1、性别：男士 {{ detail.var1[0] }} 份，占 {{ detail.var1[1] }} %；女士 {{ detail.var1[2] }} 份，占 {{ detail.var1[3] }} %。<br>
                2、年龄层次：60及以上，占 {{ detail.var2[0] }} %；51-59，占 {{ detail.var2[1] }} %；41-50，占 {{ detail.var2[2] }} %；31-40，占 {{ detail.var2[3] }} %；21-30，占 {{ detail.var2[4] }} %；20及以下，占 {{ detail.var2[5] }} %<br>
                3、文化程度：硕士以上，占 {{ detail.var3[0] }} %；大学本科，占 {{ detail.var3[1] }} %；大学专科，占 {{ detail.var3[2] }} %；中专/高中，占 {{ detail.var3[3] }} %；初中及以下 {{ detail.var3[4] }} %。<br>
                4、居住地：南京市，占 {{ detail.var4[0] }} %；外地，占 {{ detail.var4[1] }} %。<br>
                5、出行方式：自驾，占 {{ detail.var5[0] }} %；出租，占 {{ detail.var5[1] }} %；公交/地铁，占 {{ detail.var5[2] }} %; 自行车，占 {{ detail.var5[3] }} %;步行，占 {{ detail.var5[4] }} %。<br>
                6、您是第几次来此公园：第1次，占 {{ detail.var6[0] }} %；第2次，占 {{ detail.var6[1] }} %；第3次或第3次以上，占 {{ detail.var6[2] }} %。<br>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <p>二、游客此次游览感受</p>
            </td>
          </tr>
          <tr>
            <td>序号</td>
            <td>调查项目</td>
            <td>满意100分</td>
            <td>基本满意80分</td>
            <td>一般60分</td>
            <td>不满意0分</td>
            <td>不满意原因汇总</td>
            <td>单项满意率</td>
          </tr>
          <tr>
            <td>1</td>
            <td>园林景观（权重25%）</td>
            <td>{{ detail.var7[0] }}</td>
            <td>{{ detail.var7[1] }}</td>
            <td>{{ detail.var7[2] }}</td>
            <td>{{ detail.var7[3] }}</td>
            <td>{{ detail.var7[4].join('；') }}</td>
            <td>{{ detail.var7[5] }}%</td>
          </tr>
          <tr>
            <td>2</td>
            <td>娱乐项目（权重15%）</td>
            <td>{{ detail.var8[0] }}</td>
            <td>{{ detail.var8[1] }}</td>
            <td>{{ detail.var8[2] }}</td>
            <td>{{ detail.var8[3] }}</td>
            <td>{{ detail.var8[4].join('；') }}</td>
            <td>{{ detail.var8[5] }}%</td>
          </tr>
          <tr>
            <td>3</td>
            <td>人员服务（权重10%）</td>
            <td>{{ detail.var9[0] }}</td>
            <td>{{ detail.var9[1] }}</td>
            <td>{{ detail.var9[2] }}</td>
            <td>{{ detail.var9[3] }}</td>
            <td>{{ detail.var9[4].join('；') }}</td>
            <td>{{ detail.var9[5] }}%</td>
          </tr>
          <tr>
            <td>4</td>
            <td>环境卫生（权重10%）</td>
            <td>{{ detail.var10[0] }}</td>
            <td>{{ detail.var10[1] }}</td>
            <td>{{ detail.var10[2] }}</td>
            <td>{{ detail.var10[3] }}</td>
            <td>{{ detail.var10[4].join('；') }}</td>
            <td>{{ detail.var10[5] }}%</td>
          </tr>
          <tr>
            <td>5</td>
            <td>服务设施（权重10%）</td>
            <td>{{ detail.var11[0] }}</td>
            <td>{{ detail.var11[1] }}</td>
            <td>{{ detail.var11[2] }}</td>
            <td>{{ detail.var11[3] }}</td>
            <td>{{ detail.var11[4].join('；') }}</td>
            <td>{{ detail.var11[5] }}%</td>
          </tr>
          <tr>
            <td>6</td>
            <td>安全保障（权重10%）</td>
            <td>{{ detail.var12[0] }}</td>
            <td>{{ detail.var12[1] }}</td>
            <td>{{ detail.var12[2] }}</td>
            <td>{{ detail.var12[3] }}</td>
            <td>{{ detail.var12[4].join('；') }}</td>
            <td>{{ detail.var12[5] }}%</td>
          </tr>
          <tr>
            <td>7</td>
            <td>餐饮配套服务（权重5%）</td>
            <td>{{ detail.var13[0] }}</td>
            <td>{{ detail.var13[1] }}</td>
            <td>{{ detail.var13[2] }}</td>
            <td>{{ detail.var13[3] }}</td>
            <td>{{ detail.var13[4].join('；') }}</td>
            <td>{{ detail.var13[5] }}%</td>
          </tr>
          <tr>
            <td>8</td>
            <td>购物配套服务（权重5%）</td>
            <td>{{ detail.var14[0] }}</td>
            <td>{{ detail.var14[1] }}</td>
            <td>{{ detail.var14[2] }}</td>
            <td>{{ detail.var14[3] }}</td>
            <td>{{ detail.var14[4].join('；') }}</td>
            <td>{{ detail.var14[5] }}%</td>
          </tr>
          <tr>
            <td>9</td>
            <td>交通配套服务（权重5%）</td>
            <td>{{ detail.var15[0] }}</td>
            <td>{{ detail.var15[1] }}</td>
            <td>{{ detail.var15[2] }}</td>
            <td>{{ detail.var15[3] }}</td>
            <td>{{ detail.var15[4].join('；') }}</td>
            <td>{{ detail.var15[5] }}%</td>
          </tr>
          <tr>
            <td>10</td>
            <td>其他服务（权重5%）</td>
            <td>{{ detail.var16[0] }}</td>
            <td>{{ detail.var16[1] }}</td>
            <td>{{ detail.var16[2] }}</td>
            <td>{{ detail.var16[3] }}</td>
            <td>{{ detail.var16[4].join('；') }}</td>
            <td>{{ detail.var16[5] }}%</td>
          </tr>
          <tr>
            <td colspan="8" >
              <p class="text">综合满意率为：{{ detail.zhmyl }} %</p>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <p>三、游客对公园总体评价</p>
              <p>满意的占 {{ detail.var17[0] }} %；基本满意占： {{ detail.var17[1] }} %；一般占： {{ detail.var17[2] }} %；不满意占： {{ detail.var17[3] }} %，主要原因汇总：{{ detail.var17[4].join('；') }}</p>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <p>四、游客对公园单项评价前三位和后三位</p>
              <p>1、前三位：{{ detail.top3 }}</p>
              <p>2、后三位：{{ detail.last3 }}</p>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <p>五、游客提出改进意见汇总</p>
              <p>{{ detail.var20.join('；') }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'QuestionsurveyDetail2',
  data () {
    return {
      formData: {
        startTime: '',
        endTime: ''
      },
      detail: {
        dcxx: [], // 调查信息(时间、数量)
        var1: [], // 性别
        var2: [], // 年龄层次
        var3: [], // 文化程度
        var4: [], // 居住地
        var5: [], // 出行方式
        var6: [], // 您是第几次来滨江公园
        var7: [], // 园林景观
        var8: [], // 娱乐项目
        var9: [], // 人员服务
        var10: [], // 环境卫生
        var11: [], // 服务设施
        var12: [], // 安全保障
        var13: [], // 餐饮配套服务
        var14: [], // 购物配套服务
        var15: [], // 交通配套服务
        var16: [], // 其它服务
        zhmyl: '', // 综合满意率
        top3: '', // 游客对对公园单项评价前三位
        last3: '', // 游客对对公园单项评价后三位
        var20: [] // 游客提出改进意见汇总
      },
      dateRange: []
    }
  },
  computed: {
    parkName () {
      return this.$route.query.parkName
    }
  },
  watch: {
  },
  created () {
    this.formData.startTime = this.$dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    this.formData.endTime = this.$dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    this.dateRange = [this.formData.startTime, this.formData.endTime]
    this.formData.questionnaireId = this.$route.params.id
    this.loadData()
    // do something
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.questionSurvey.selectMydQuestionnaireResult, this.formData).then(res => {
        console.log('res', res)
        this.detail = res || {}
      })
    },
    dataChange (data) {
      this.formData.startTime = (data && data[0]) || ''
      this.formData.endTime = (data && data[1]) || ''
    }
  }
}
</script>

<style scoped lang="scss">
.form-header {
  display: flex;
  justify-content: space-between;
}

.bg {
  background-color: #F6F7FB;
  height: 100%;
}
.content {
  width: 720px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  .park, .title {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 40px;
  }

  .tips {
    width: 720px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    margin-top: 7px;
    text-align: left;
  }

  table {
    width: 720px;
    border-collapse: collapse;
    border: 1px solid #ddd;
    background: #ffffff;
    margin-top: 13px;

    td {
      border:1px solid #ddd;
      height: 48px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      text-align: center;

      p {
        text-align: left;
        padding: 10px;
      }
    }
  }
}
</style>
